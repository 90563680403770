export default class Payment {
  public static readonly SET_TARIFF = "PAYMENT_SET_TARIFF";
  public static readonly SET_PAYMENT = "PAYMENT_SET_PAYMENT";
  public static readonly SET_PAYMENT_LINK = "PAYMENT_SET_PAYMENT_LINK";

  public static setTariff(data: any) {
    return {
      data,
      type: Payment.SET_TARIFF,
    };
  }

  public static setPayment(data: any) {
    return {
      data,
      type: Payment.SET_PAYMENT,
    };
  }

  public static setPaymentLink(data: any) {
    return {
      data,
      type: Payment.SET_PAYMENT_LINK,
    };
  }
}
