import { Rating as RatingType } from "@interface/entities/profile/Rating";
import { BaseAction } from "@interface/common";

export default class UserRating {
  public static readonly SET_DATA = "USER_RATING_SET_DATA";
  public static readonly SET_STATUS = "USER_RATING_SET_STATUS";
  public static readonly SET_ERROR = "USER_RATING_SET_ERROR";

  public static setData(data: RatingType): BaseAction<UserRating> {
    return {
      type: UserRating.SET_DATA,
      data,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: UserRating.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: UserRating.SET_ERROR,
    };
  }
}
